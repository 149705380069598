
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";

export default defineComponent({
  name: "kraepelin-usr",
  components: {
  },
  setup() {
    const iframeUrl = process.env.VUE_APP_API_URL_IFRAME + "/GO/Kraepelin/Test";

    onMounted(() => {
      setCurrentPageBreadcrumbs("KRAEPELIN", [])
    })

     // Function to open URL in a new tab
    const openInNewTab = () => {
      window.open(iframeUrl, '_blank'); // Opens the URL in a new tab
    };

    return {
        iframeUrl,
        openInNewTab,
    };
  },
});
